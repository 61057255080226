<template>
    <!-- 选择商户弹窗 -->
    <el-dialog
      title="选择商户"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="700px">
      <div class="flexbox flex-align-center">
        <div class="search-item">
          <span>商户名称</span>
          <el-input v-model="shopName" placeholder="请输入商户名称" style="width:200px;" clearable></el-input>
        </div>
        <el-button type="primary" @click="searchShop">搜索</el-button>
        <el-button type="info" @click="clearShop">清空</el-button>
      </div>
      <el-table :data="tableData" ref="tables" style="width: 100%;margin-top: 20px;" border
                row-key="shop_id" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" reserve-selection></el-table-column>
        <el-table-column prop="logo_path" label="商户头像" align="center">
          <template slot-scope="scope">
            <img class="logo-img" :src="scope.row.logo_path" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="shop_id" label="商户号" align="center"></el-table-column>
        <el-table-column prop="shop_name" label="商户名" align="center"></el-table-column>
        <el-table-column prop="contact_tel" label="联系方式" align="center"></el-table-column>
        <el-table-column prop="address" label="店铺地址" align="center"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="mt10"
        background
        :page-count="totalPage"
        :current-page.sync="currentPage"
        @current-change="getShopList">
      </el-pagination>
      <el-divider></el-divider>
      <div class="flexbox flex-justify-center">
        <el-button type="primary" @click="selectShop">确定</el-button>
        <el-button type="primary" plain @click="dialogVisible = false">取消</el-button>
      </div>
    </el-dialog>
</template>
<script>
import { $sg_platCouponChoseList } from '@/api/schoolGroupShops';

export default {
  data() {
    return {
      dialogVisible: false,
      shopName: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      addressList: [],
      multipleSelection: [],
      area_id: 0,
    };
  },
  methods: {
    // 打开弹窗
    async open(data, area_id) {
      this.area_id = area_id;
      this.dialogVisible = true;
      this.multipleSelection = [];
      this.currentPage = 1;
      this.$nextTick(() => {
        this.$refs.tables.clearSelection();
        if (data.length > 0) {
          data.forEach((val) => {
            this.$refs.tables.toggleRowSelection({
              ...val,
            }, true);
          });
          this.multipleSelection = data;
        }
      });
      await this.getShopList();
    },
    // 搜索商户
    searchShop() {
      this.currentPage = 1;
      this.getShopList();
    },
    // 清空商户搜索条件
    clearShop() {
      this.shopName = '';
    },
    // 获取商户列表
    getShopList() {
      const params = {
        page: this.currentPage,
        page_size: 5,
        shop_name: this.shopName,
        area_id: this.area_id,
      };
      return $sg_platCouponChoseList(params).then((res) => {
        this.tableData = res.list;
        this.totalPage = res.total_page;
      });
    },
    // 选择商户
    handleSelectionChange(val) {
      console.log(val, 'handleSelectionChange');
      this.multipleSelection = val;
    },
    // 确定选择商户
    selectShop() {
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择商户');
        return;
      }
      this.$emit('getSelectShop', this.multipleSelection);
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
  .search-item {
    margin-right: 20px;
    & > span {
      color: #666;
      margin-right: 5px;
    }
  }
  .logo-img {
    width: 60px;
    height: 60px;
  }
</style>
