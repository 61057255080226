import api from './api';
// 获取地区信息
export const $agent_fund = () => api.get('/api/agent/biz/checkOut/defaultWithdraw');
export const $agent_status = () => api.get('/j/agent/agent/queryState');
export const $agent_bind_type = () => api.get('/api/agent/biz/checkOut/getWithdrawInfo');
export const $agent_sumbit_account = (params) => api.post('/api/agent/biz/checkOut/auth/bindBankCard', params);
export const $agent_withdraw = (params) => api.post('/api/agent/biz/checkOut/addWithdraw', params);
export const $agent_withdraw_record = (params) => api.get('/api/agent/biz/checkOut/getWithdrawLog', params); 
export const $agent_query_phone = () => api.get('/api/agent/biz/checkOut/getCheckOutPhone');

export const $agent_profit = (params) => api.postNoMessage('/j/agent/order/findAgentOrderProfitDTO', params);
// -----------上传零点到it-----------
// export const $$p_uploadForKindeditor = ({ params, data }) => api.postNoRejectNoTips('/alone/v1/Upload/uploadImageBase64', data, { params });
// // 图库管理-添加平台图库
// export const $sp_createPlatPicture = (params) => api.postNoTips('/platform/v1/PlatGallery/createPlatPicture', params);
// // 获取平台图库
// export const $sg_getGoodsPictures = (params) => api.get('/mall/v1/GoodsManage/getGoodsPictures', params);

// export const $p_sendCaptchaSms = (params) => api.post('/j/sms/sendCaptchaSms', params);

// // 图库管理-添加平台图库
// export const $p_createPlatPicture = (params) => api.postNoTips('/platform/v1/PlatGallery/createPlatPicture', params);

// // 上传图片
// export const $p_uploadImageBase64 = ({ params, data }) => api.postNoRejectNoTips('/alone/v1/Upload/uploadImageBase64', data, { params });

// 生成充值订单
export const $sp_createRechargeOrder = (params) => api.postNoMessage('/agent/v1/AgentPay/createRechargeOrder', params);

// 查询订单是否已支付
export const $sg_queryPayState = (params) => api.get('/agent/v1/AgentPay/queryPayState', params);

// 储值账户运营钱包列表
export const $sg_storeFundList = (params) => api.get('/w/agent/storeFund/list', params);

// 储值账户运营钱包详情
export const $sg_storeFundDetails = (params) => api.get('/w/agent/storeFund/details', params);

// 储值账户提现
export const $sp_withdrawApply = (params) => api.post('/w/withdraw/apply', params);

// 提现列表
export const $sg_withdrawList = (params) => api.get('/w/withdraw/list', params);

// 保存预警设置
export const $sp_withdrawWarning = (params) => api.post('/w/withdraw/warning', params);

// 预警设置信息
export const $sg_withdrawAccount = (params) => api.get('/w/withdraw/account', params);

// 查看账户金额
export const $sg_storeFundAmount = (params) => api.get('/w/agent/storeFund/amount', params);
