<template>
  <div class="addCoupon-page">
    <div class="addCoupon-item">
      <span>优惠券名称</span>
      <el-input v-model="form.coupon_name" placeholder="请输入优惠券名称" style="width:360px;" clearable></el-input>
    </div>
    <div class="addCoupon-item">
      <span>活动周期</span>
      <el-date-picker
        v-model="activityTime"
        value-format="yyyy-MM-dd HH:mm:ss"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        >
      </el-date-picker>
    </div>
    <div class="addCoupon-item">
      <span>使用门槛</span>
      <el-radio v-model="is_full_price" :label="1">无门槛使用</el-radio>
      <el-radio v-model="is_full_price" :label="2">
        <span>订单满</span>
        <el-input v-model="form.full_price" type="number" style="width:120px;margin: 0px 5px;"></el-input>
        <span>元可用</span>
      </el-radio>
    </div>
    <div class="addCoupon-item">
      <span>发行总量</span>
      <el-input v-model.number="form.limit_num" :maxlength="6" placeholder="请输入发行总量" style="width:360px;">
        <div slot="suffix" class="unit">张</div>
      </el-input>
    </div>
    <div class="tips-txt">修改优惠券总量时只能增加不能减少，请谨慎设置</div>
    <div class="addCoupon-item">
      <span>用券日期</span>
      <el-radio v-model="form.time_limit" :label="1">定期使用</el-radio>
      <el-radio v-model="form.time_limit" :label="2">
        <span>领券后生效</span>
        <el-input v-model.number="form.receive_time" style="width:120px;margin: 0px 5px;"></el-input>
        <span>天</span>
      </el-radio>
    </div>
    <div class="time-limit-box" v-if="form.time_limit === 1">
      <div v-for="(item, index) in receiveTime" :key="index">
        <el-time-picker
          value-format="HH:mm"
          format="HH:mm"
          style="width: 120px;"
          v-model="item.start_time">
        </el-time-picker>
        <span class="to">至</span>
        <el-time-picker
          value-format="HH:mm"
          format="HH:mm"
          style="width: 120px;"
          v-model="item.end_time">
        </el-time-picker>
        <i class="el-icon-circle-plus-outline pointer" v-if="index == 0 && receiveTime.length < 5" @click="addReceiveTime"></i>
        <i class="el-icon-delete pointer" v-if="index > 0" @click="delReceiveTime(index)"></i>
      </div>
    </div>
    <div class="addCoupon-item">
      <span>适用商圈</span>
      <el-select v-model="form.area_id" style="width: 260px" placeholder="请选择商圈">
        <el-option
          v-for="item in areaOptions"
          :key="item.id"
          :label="item.trading_area_name"
          :value="item.id">
        </el-option>
      </el-select>
    </div>
    <el-divider></el-divider>
    <div class="addCoupon-item">
      <span>活动对象</span>
      <el-radio v-model="is_shop_limit" :label="1">全部商户</el-radio>
      <el-radio v-model="is_shop_limit" :label="2">指定商户</el-radio>
    </div>
    <div class="table-box" v-if="is_shop_limit === 2">
      <el-button type="primary" style="margin-top: 20px;" @click="openSearchShopDialog">选择商户</el-button>
      <el-table :data="tableData" style="width: 100%;margin-top: 20px;" border>
        <el-table-column prop="" label="商户头像" align="center">
          <template slot-scope="scope">
            <img class="logo-img" :src="scope.row.logo_path" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="shop_id" label="商户号" align="center"></el-table-column>
        <el-table-column prop="shop_name" label="商户名" align="center"></el-table-column>
        <el-table-column prop="contact_tel" label="联系方式" align="center"></el-table-column>
        <el-table-column prop="address" label="店铺地址" align="center"></el-table-column>
        <el-table-column prop="" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="delShop(scope.$index)">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-divider></el-divider>
    <div class="addCoupon-item">
      <span>限领规则</span>
      <el-radio v-model="is_pur_limit_num" :label="1">不限领</el-radio>
      <el-radio v-model="is_pur_limit_num" :label="2">限领</el-radio>
    </div>
    <div class="addCoupon-item" v-if="is_pur_limit_num === 2">
      <span>每人领取</span>
      <el-input v-model="form.pur_limit_num" placeholder="请输入" style="width:260px;">
        <div slot="suffix" class="unit">份</div>
      </el-input>
    </div>
    <div class="addCoupon-item">
      <span>优惠金额</span>
      <el-input :value="form.ticket_money" type="number" placeholder="请输入" style="width:260px;"
                :disabled="disabled" @input="ticketMoneyInput">
        <div slot="suffix" class="unit">元</div>
      </el-input>
    </div>
    <div class="addCoupon-item">
      <span>成本补贴</span>
      <el-input v-model.number="form.subsidy" placeholder="请输入" style="width:260px;" :disabled="disabled">
        <div slot="suffix" class="unit">%</div>
      </el-input>
    </div>
    <div class="tips">活动优惠金额代理商补贴占比，输入数值为0时，默认全部由商户承担</div>
    <div class="addCoupon-item">
      <span>预算成本</span>
      <span style="width: auto;">{{budgetCost}}元</span>
      <el-tooltip class="item" effect="dark" content="预算成本=发行总量*优惠金额*成本补贴比例" placement="top-start">
        <i class="el-icon-question"></i>
      </el-tooltip>
      <div class="tips" v-if="budgetCost > balance">
        当前预储值账户余额不足
        <span @click="goRecharge">去充值</span>
      </div>
    </div>
    <el-divider></el-divider>
    <el-button type="primary" style="margin-left: 60px;" @click="submit">确定</el-button>
    <el-button type="primary" plain @click="$router.go(-1)">取消</el-button>
    <!-- 从历史优惠券复制新增 -->
    <div class="add-history-btn" @click="openHistoryCoupon">从历史优惠券复制新增</div>
    <el-drawer
      title="从历史优惠券复制新增"
      :visible.sync="showDrawer"
      direction="rtl">
      <el-table :data="historyTableData" style="width: 100%" border>
        <el-table-column prop="activity_ticket_id" label="活动编号" align="center" width="80"></el-table-column>
        <el-table-column prop="coupon_name" label="优惠券名称" align="center"></el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="160" align="center"></el-table-column>
        <el-table-column prop="shopFinance" label="操作" align="center">
          <template slot-scope="scope">
            <el-popconfirm @confirm="copyHistoryCoupon(scope.row)"
              title="确认复制优惠券信息？(当前新增优惠券信息将被清空)">
              <el-button type="text" slot="reference">复制新增</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt10" 
        background 
        :page-count="historyTotalPage" 
        :current-page.sync="historyCurrentPage" 
        @current-change="getHistoryCouponData">
      </el-pagination>
    </el-drawer>
    <!-- 选择商户弹窗 -->
    <SelectShopDialog ref="selectShopDialog" @getSelectShop="getSelectShop" />
  </div>
</template>
<script>
import {
  $sp_platCouponSave,
  $sg_platCouponInfo,
  $sg_areas,
  $sg_activityShopList,
  $sg_platCouponList,
} from '@/api/schoolGroupShops';
import { $sg_storeFundAmount } from '@/api/bill';
import SelectShopDialog from './components/selectShopDialog.vue';

export default {
  components: {
    SelectShopDialog,
  },
  data() {
    return {
      type: '', // 1-新增，2-编辑，3-复制
      activity_ticket_id: '',
      disabled: false,
      is_full_price: 1, // 使用门槛
      is_pur_limit_num: 1, // 限购规则
      is_shop_limit: 1, // 限制商家
      form: {
        coupon_name: '',
        time_limit: 1,
        receive_time: '',
        full_price: '',
        limit_num: '',
        area_id: 0,
        shop_limit: [],
        pur_limit_num: '',
        ticket_money: '',
        subsidy: '',
      },
      activityTime: '',
      receiveTime: [{
        start_time: '',
        end_time: '',
      }],
      time: '',
      areaOptions: [],
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      balance: 0, // 账户余额
      showDrawer: false, // 历史优惠券弹窗
      historyTotalPage: 1,
      historyCurrentPage: 1,
      historyTableData: [],
    };
  },
  computed: {
    // 计算预算成本
    budgetCost() {
      return (this.form.limit_num * this.form.ticket_money * (this.form.subsidy / 100)).toFixed(2) || 0;
    },
  },
  async created() {
    this.getAreaList();
    this.storeFundAmount();
    this.activity_ticket_id = this.$route.query.activity_ticket_id;
    this.type = this.$route.query.type || '';
    if (this.activity_ticket_id) {
      await this.getCouponDetail();
      await this.activityShopList();
      if (this.type !== '3') {
        this.disabled = true;
      }
    }
  },
  methods: {
    // 获取优惠券详情
    getCouponDetail() {
      const params = {
        activity_ticket_id: this.activity_ticket_id,
      };
      return $sg_platCouponInfo(params).then((res) => {
        this.form = {
          coupon_name: res.coupon_name,
          time_limit: res.time_limit,
          full_price: '',
          limit_num: res.limit_num,
          receive_time: '',
          area_id: res.area_id,
          shop_limit: [],
          pur_limit_num: '',
          ticket_money: res.ticket_money,
          subsidy: res.subsidy,
        };
        this.activityTime = [res.start_time, res.dead_time];
        if (+res.full_price === 0) {
          this.is_full_price = 1;
          this.form.full_price = '';
        } else {
          this.is_full_price = 2;
          this.form.full_price = res.full_price;
        }
        if (res.time_limit === 2) {
          this.form.receive_time = res.receive_time;
        } else {
          this.form.receive_time = '';
          this.receiveTime = res.receive_time;
        }
        if (res.shop_limit.length === 0 || res.shop_limit[0] === '0') {
          this.is_shop_limit = 1;
          this.form.shop_limit = [];
        } else {
          this.is_shop_limit = 2;
          this.form.shop_limit = res.shop_limit;
        }
        if (+res.pur_limit_num === 0) {
          this.is_pur_limit_num = 1;
          this.form.pur_limit_num = '';
        } else {
          this.is_pur_limit_num = 2;
          this.form.pur_limit_num = res.pur_limit_num;
        }
      });
    },
    // 查看账户金额
    storeFundAmount() {
      $sg_storeFundAmount().then((res) => {
        this.balance = +res.balance || 0;
      });
    },
    // 添加用券日期
    addReceiveTime() {
      this.receiveTime.push({
        start_time: '',
        end_time: '',
      });
    },
    // 删除用券日期
    delReceiveTime(index) {
      this.receiveTime.splice(index, 1);
    },
    // 获取商户列表
    activityShopList() {
      if (this.form.shop_limit.length === 0) return Promise.resolve();
      const params = {
        shop_ids: `${this.form.shop_limit}`,
      };
      return $sg_activityShopList(params).then((res) => {
        this.tableData = res;
      });
    },
    // 获取商圈列表
    getAreaList() {
      $sg_areas().then((res) => {
        const allArea = [{
          id: 0,
          trading_area_name: '全部',
        }];
        this.areaOptions = [...allArea, ...res];
      });
    },
    // 打开搜索商户弹窗
    openSearchShopDialog() {
      this.$refs.selectShopDialog.open(this.tableData, this.form.area_id);
    },
    // 获取已选择的商户
    getSelectShop(data) {
      console.log(data, '已经选择的商户列表');
      this.tableData = data;
    },
    // 移除商户
    delShop(index) {
      this.tableData.splice(index, 1);
    },
    // 优惠金额input事件
    ticketMoneyInput(e) {
      // 仅支持输入最大3位数+2位小数的正数
      // if (!e & e !== 0) {
      //   this.form.ticket_money = '';
      //   return;
      // }
      // const reg = /^(0|[1-9]\d{0,2})(\.\d{0,2})?$/;
      // if (!reg.test(e)) return;
      this.form.ticket_money = e;
    },
    // 提交
    async submit() {
      await this.$validator({
        ...this.form,
      }, [
        'coupon_name/require/优惠券名称',
        'limit_num/require/发行总量',
        'ticket_money/require/优惠金额',
        'subsidy/require|min:0|max:100/成本补贴',
      ]);
      if (!this.activityTime) {
        this.$message.error('活动周期不能为空');
        return;
      }
      if (!/^(0|[1-9]\d{0,2})(\.\d{1,2})?$/.test(this.form.ticket_money)) {
        this.$message.error('优惠金额仅支持输入最大3位数+2位小数的正数');
        return;
      }
      if (this.form.limit_num <= 0) {
        this.$message.error('发行总量必须大于0');
        return;
      }
      const start_time = new Date(this.activityTime[0]).getTime();
      const nowTime = new Date().getTime();
      if (start_time < nowTime) {
        this.$message.error('活动周期不能选择过去时间');
        return;
      }
      const form = JSON.parse(JSON.stringify(this.form));
      form.start_time = this.activityTime[0];
      form.dead_time = this.activityTime[1];
      if (this.is_full_price === 1) {
        form.full_price = 0;
      } else if (!form.full_price) {
        this.$message.error('使用门槛不能为空');
        return;
      }
      if (this.form.time_limit === 1) {
        let flag = true;
        let tips = '';
        this.receiveTime.forEach((val) => {
          if (!val.start_time || !val.end_time) {
            flag = false;
            tips = '用券日期不能为空';
            return;
          }
          if (val.start_time >= val.end_time) {
            flag = false;
            tips = '用券日期结束时间必须要大于开始时间';
          }
        });
        if (!flag) {
          this.$message.error(tips);
          return;
        }
        form.receive_time = this.receiveTime;
      } else if (!this.form.receive_time && this.form.receive_time !== 0) {
        this.$message.error('用券日期不能为空');
        return;
      } else if (this.form.receive_time < 1 || this.form.receive_time > 99) {
        this.$message.error('用券日期只能为1-99的正整数');
        return;
      }
      if (this.is_shop_limit === 1) {
        form.shop_limit = [];
      } else if (this.tableData.length === 0) {
        this.$message.error('活动对象不能为空');
        return;
      } else {
        const arr = [];
        this.tableData.forEach((val) => {
          arr.push(val.shop_id);
        });
        form.shop_limit = arr;
      }
      if (this.is_pur_limit_num === 1) {
        form.pur_limit_num = 0;
      } else if (!form.pur_limit_num || +form.pur_limit_num <= 0) {
        this.$message.error('每人领取必须大于0');
        return;
      }
      if (this.activity_ticket_id && this.type !== '3') {
        form.activity_ticket_id = this.activity_ticket_id;
      }
      $sp_platCouponSave(form).then(() => {
        this.$router.go(-1);
      });
    },
    // 去充值
    goRecharge() {
      this.$router.push({
        name: 'OperatingWallet',
      });
    },
    // 打开历史优惠券弹窗
    openHistoryCoupon() {
      this.showDrawer = true;
      this.historyCurrentPage = 1;
      this.getHistoryCouponData();
    },
    // 请求历史优惠券就列表数据
    getHistoryCouponData() {
      const params = {
        page: this.historyCurrentPage,
        page_size: 10,
      };
      $sg_platCouponList(params).then((res) => {
        this.historyTableData = res.list;
        this.historyTotalPage = res.total_page;
      });
    },
    // 复制新增
    async copyHistoryCoupon(row) {
      this.type = '3';
      this.activity_ticket_id = row.activity_ticket_id;
      await this.getCouponDetail();
      await this.activityShopList();
    },
  },
};
</script>
<style lang="scss" scoped>
.addCoupon-page {
  width: 100%;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .addCoupon-item {
    width: 100%;
    margin-top: 20px;
    & > span {
      display: inline-block;
      width: 120px;
      text-align: right;
      margin-right: 10px;
      color: #444;
    }
    .unit {
      height: 36px;
      line-height: 36px;
    }
    & > i {
      margin-left: 5px;
      font-size: 16px;
      cursor: pointer;
      user-select: none;
    }
  }
  .time-limit-box {
    width: 100%;
    padding-left: 130px;
    box-sizing: border-box;
    & > div {
      margin-top: 15px;
      .to {
        font-size: 14px;
        color: #666;
        margin: 0px 5px;
      }
      & > i {
        margin-left: 5px;
      }
    }
  }
  .tips-txt {
    width: 100%;
    padding-left: 130px;
    font-size: 14px;
    color: #999;
    margin-top: 10px;
    box-sizing: border-box;
  }
  .tips {
    width: 300px;
    background-color: #f5f5f5;
    color: #999;
    margin-left: 130px;
    font-size: 12px;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 10px;
    line-height: 1.5;
    span {
      color: #F23026;
      cursor: pointer;
      user-select: none;
    }
  }
  .table-box {
    width: 100%;
    padding-left: 130px;
    box-sizing: border-box;
    .logo-img {
      width: 60px;
      height: 60px;
    }
  }
  .add-history-btn {
    color: #F23026;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    user-select: none;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #F23026;
  }
}
</style>
